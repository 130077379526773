export function implementInterviewLogic(): void {
  const logicElement = document.getElementById(
    "id_interview_logic"
  ) as HTMLInputElement;
  const logic = logicElement?.value;
  implementLogics(logic, "interview-screen-list");
}

export function implementScreensLogics(): void {
  const interview_form: HTMLElement | null = document.getElementById(
    "interview-screen-list"
  );
  if (!interview_form) return;

  let screensLogicElementsArray: NodeListOf<HTMLInputElement> =
    // interview_form.querySelectorAll('input[id^="id_logic_"]:not([id*="%"])');
    interview_form.querySelectorAll('input[id^="id_logic_"]');
  console.log(screensLogicElementsArray);

  let i: number = 0;
  while (i < screensLogicElementsArray.length) {
    const screenLogicElement: HTMLInputElement = screensLogicElementsArray[i];
    const ifElementInDOM: HTMLElement | null = document.getElementById(
      screenLogicElement.id
    );
    // this is the p node for the logic element. travel to div, then card-contents, then the p node
    const pNodeOfscreenLogicElement =
      screenLogicElement.parentElement?.parentElement?.parentElement;
    if (
      screenLogicElement.dataset.logic &&
      // just running elements with parent 'interview-screen-list' coz subsequent children logics of rest are run while using USE command
      pNodeOfscreenLogicElement?.dataset.parent === "interview-screen-list" &&
      // since we are querying all the logic elements in the beginning, need to make sure it is actually in the dom at this point
      ifElementInDOM
    ) {
      const screenLogic: string = screenLogicElement.dataset.logic;
      implementLogics(screenLogic, pNodeOfscreenLogicElement.id);
    } else if (pNodeOfscreenLogicElement && !ifElementInDOM) {
      removeScreen(pNodeOfscreenLogicElement.id);
    }
    i++;
  }

  // const changedScreensLogicsArray: NodeListOf<HTMLInputElement> =
  //   interview_form.querySelectorAll('input[id^="id_logic_"]:not([id*="%"])');

  // if (screensLogicsArray.length != changedScreensLogicsArray.length)
  //   implementScreensLogics();
  // navigateScreens();
}

export function updateInputElementsForRole(
  role: string,
  data: Record<string, string>,
  suffix?: number
): void {
  for (const fieldName in data) {
    if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
      let inputId: string;
      if (suffix) {
        inputId = `id_${role}%${suffix}.${fieldName}`;
      } else {
        inputId = `id_${role}%0.${fieldName}`;
      }

      // Check if an input field with the ID already exists
      const existingInput = document.getElementById(
        inputId
      ) as HTMLInputElement | null;

      if (existingInput) {
        // Update the existing input value with the corresponding value from the JSON response
        existingInput.value = data[fieldName];
      } else {
        // Create a new input field if it doesn't exist
        const newInput = document.createElement("input");
        newInput.type = "hidden";
        newInput.name = inputId.substring(3);
        newInput.id = inputId;
        newInput.value = data[fieldName];

        // Append the new input to the parent paragraph
        const parentParagraph = document.getElementById("p_roles");
        if (parentParagraph) {
          parentParagraph.appendChild(newInput);
        } else {
          console.error("Parent paragraph element not found");
        }
      }
    }
  }
}

export function deleteScreen(screen: HTMLElement): void {
  let screenCount =
    parseInt(screen.getAttribute("data-screen-count") || "0") || 0;

  if (screenCount > 0) {
    const elementIdToDelete = screen.id.slice(0, -2) + "%" + screenCount;
    const elementToDelete = document.getElementById(elementIdToDelete);

    if (elementToDelete) {
      elementToDelete.remove();

      // Decrease screen count and update the attribute
      screenCount--;
      screen.setAttribute("data-screen-count", screenCount.toString());
    }

    // another counter also needs to be updated
    const counterElementId = screen.id.replace("p_", "").slice(0, -2);
    const counterElement = document.getElementById(
      counterElementId
    ) as HTMLInputElement | null;
    if (counterElement) {
      counterElement.value = (
        (parseInt(counterElement.value) || 1) - 1
      ).toString();
    }

    removeScreen(elementIdToDelete);
  }
}

export function repeatScreen(
  screen: HTMLElement,
  programmaticFlag: boolean = false
): void {
  // Function to recursively update element IDs
  function updateElementIds(element: HTMLElement): void {
    if (element.id) {
      // Push to old array
      if (element.id.startsWith("id_"))
        oldElements.push(element.id.substring(3));

      // Change ids and names here
      // Change number
      if (element.id.match(/\d+$/)) {
        element.id = element.id.replace(/\d+$/, screenCount.toString());
        if (
          element instanceof HTMLSelectElement ||
          element instanceof HTMLInputElement
        ) {
          element.name = element.name.replace(/\d+$/, screenCount.toString());
        }
      }
      // Append number
      else {
        element.id = element.id + "%" + screenCount;
        if (
          element instanceof HTMLSelectElement ||
          element instanceof HTMLInputElement
        ) {
          element.name = element.name + "%" + screenCount;
        }
      }
      // Push to new array
      if (element.id.startsWith("id_"))
        newElements.push(element.id.substring(3));
    }

    // Recursively update IDs for child elements and remove screens inside it
    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement;
      updateElementIds(child);
    }
  }

  // function to clear repeatable screens inside a screen
  function removeRepeatedScreens(clonedScreen: HTMLElement): void {
    const childElements = clonedScreen.children;
    for (let i = childElements.length - 1; i >= 0; i--) {
      const childElement = childElements[i] as HTMLElement;
      if (childElement.classList.contains("screen")) {
        clonedScreen.removeChild(childElement);
      }
    }
  }

  // function to update element names in logic field of screen and instruction logics
  function updateElementNamesInLogic(
    element: HTMLElement,
    oldElements: string[],
    newElements: string[]
  ): void {
    if (element.id && element.dataset.logic) {
      for (let i = 0; i < oldElements.length; i++) {
        const regex = new RegExp(`\\b${oldElements[i]}\\b`, "g");
        element.dataset.logic = element.dataset.logic.replace(
          regex,
          newElements[i]
        );
      }
    }

    // Recursively update IDs for child elements
    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      updateElementNamesInLogic(
        children[i] as HTMLElement,
        oldElements,
        newElements
      );
    }
  }

  let screenCount =
    parseInt(screen.getAttribute("data-screen-count") || "0") || 0;
  let maxScreenCount =
    parseInt(screen.getAttribute("data-screen-max-count") || "0") || 0;

  // check if less than max count set by limit command
  if (screenCount + 1 >= maxScreenCount) {
    console.log("tf", screenCount, maxScreenCount);
    return;
  }

  // update the counter of main screen
  screenCount++;
  screen.setAttribute("data-screen-count", screenCount.toString());

  // clone the original screen
  const clonedScreen = screen.cloneNode(true) as HTMLElement;
  clonedScreen.removeAttribute("data-screen-count");

  // clear the child screens in cloned screen
  removeRepeatedScreens(clonedScreen);

  // update ids for logics to run properly
  let oldElements: string[] = [];
  let newElements: string[] = [];

  updateElementIds(clonedScreen);
  updateElementNamesInLogic(clonedScreen, oldElements, newElements);

  // add the clonedScreen after the original
  screen.insertAdjacentElement("afterend", clonedScreen);

  // remove repeat button from cloned screen
  const repeatButton = clonedScreen.querySelector(".repeat-screen-btn");
  if (repeatButton) repeatButton.remove();

  // remove delete button from cloned screen
  const deleteButton = clonedScreen.querySelector(".delete-screen-btn");
  if (deleteButton) deleteButton.remove();

  // another counter also needs to be updated
  const counterElementId = screen.id.replace("p_", "").slice(0, -2);
  const counterElement = document.getElementById(
    counterElementId
  ) as HTMLInputElement | null;
  if (counterElement) {
    counterElement.value = (
      (parseInt(counterElement.value) || 1) + 1
    ).toString();
  }

  // add to sidebar only if user repeated the screen
  // right now, map repeats the screen programmatically
  if (!programmaticFlag) {
    // for sidebar
    const idForSidebar = clonedScreen.id;
    const nameForSidebar = clonedScreen.id.replace("p_id_", "");
    if (!clonedScreen?.dataset.parent) {
      addScreen(idForSidebar, nameForSidebar);
    } else {
      addScreen(idForSidebar, nameForSidebar, clonedScreen?.dataset.parent);
    }

    //add hide-screen class as well
    clonedScreen.classList.add("hide-screen");

    //change header
    clonedScreen.querySelector(".card-title")!.textContent = nameForSidebar;

    //run the code as well
    // implementLogics(clonedScreen., pNodeOfscreenLogicElement.id);
  }
}

export function navigateScreens() {
  let currentIndex = -1;
  const prevButtons = document.querySelectorAll(".prevButton");
  const nextButtons = document.querySelectorAll(".nextButton");

  function getScreens(): HTMLElement[] {
    return Array.from(
      document.querySelectorAll<HTMLElement>("#screens-sidebar .screen-folder")
    );
  }

  function updateButtonStates(): void {
    const screens = getScreens();
    prevButtons.forEach((button) => {
      if (currentIndex <= 0) {
        button.classList.remove("standard");
        button.classList.add("inactive");
      } else {
        button.classList.remove("inactive");
        button.classList.add("standard");
      }
    });
    nextButtons.forEach((button) => {
      if (currentIndex >= screens.length - 1) {
        button.classList.remove("standard");
        button.classList.add("inactive");
      } else {
        button.classList.remove("inactive");
        button.classList.add("standard");
      }
    });
  }

  function showCurrentScreen(): void {
    const screens = getScreens();
    if (currentIndex >= 0 && currentIndex < screens.length) {
      const currentScreen = screens[currentIndex];
      const screenId = currentScreen.id.replace("screen-", "");
      const parentId = currentScreen.getAttribute("data-parent") ?? "";
      showScreen(screenId, parentId);
    }
  }

  function addScreenClickListeners(): void {
    const screens = getScreens();
    screens.forEach((screen, index) => {
      const screenText = screen.querySelector<HTMLElement>(".screen-text");
      if (screenText) {
        screenText.onclick = () => {
          currentIndex = index;
          updateButtonStates();
          showCurrentScreen();
        };
      }
    });
  }
  prevButtons.forEach((button) => {
    button.addEventListener("click", () => {
      if (currentIndex > 0) {
        currentIndex--;
        showCurrentScreen();
        updateButtonStates();
      }
    });
  });

  nextButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const screens = getScreens();
      console.log(currentIndex, screens.length);
      if (currentIndex < screens.length - 1) {
        currentIndex++;
        showCurrentScreen();
        updateButtonStates();
      }
    });
  });

  // Initialize
  updateButtonStates();
  addScreenClickListeners();

  // Reinitialize listeners whenever a new screen is added
  const screensContainer = document.getElementById("screens-sidebar");
  if (screensContainer) {
    const observer = new MutationObserver(() => {
      addScreenClickListeners();
      updateButtonStates(); // Ensure buttons reflect the new state
    });

    observer.observe(screensContainer, { subtree: true, childList: true });
  }
}

interface FormData {
  [key: string]: string;
}

export function autoFillForm(formData: FormData): void {
  // Iterate through each field in the form data and update existing elements
  for (const fieldName in formData) {
    if (Object.prototype.hasOwnProperty.call(formData, fieldName)) {
      const fieldValue = formData[fieldName];
      const fieldElement = document.getElementById(
        "id_" + fieldName
      ) as HTMLInputElement | null;

      if (fieldElement && fieldElement.id !== "id_make_files") {
        const parentElement = fieldElement.parentNode?.parentNode?.parentNode
          ?.parentNode as HTMLElement;

        if (
          parentElement &&
          parentElement.classList.contains("screen") &&
          fieldValue !== ""
        ) {
          fieldElement.value = fieldValue;
        }
      }
    }
  }
  document.dispatchEvent(
    new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: true,
    })
  );
}

export function initializeRepeatScreens(
  formData: FormData,
  screensData: Record<string, string | number>
) {
  // Iterate through each screen in screensData
  for (const screenName in screensData) {
    const repeatCount: number =
      parseInt(screensData[screenName] as string, 10) || 0;

    // Get the current value of the screen field and handle it safely
    const screenInput = document.getElementById(
      `id_${screenName}`
    ) as HTMLInputElement | null;
    if (!screenInput) {
      console.warn(`Screen '${screenName}' not found`);
      continue;
    }

    let startCounter: number = parseInt(screenInput.value, 10) || 0;

    // Iterate to click the button based on repeatCount
    for (let i = startCounter; i < repeatCount; i++) {
      const screenDiv = document.getElementById(`p_id_${screenName}%0`);
      if (screenDiv) {
        const repeatBtn = screenDiv.querySelector(
          ".repeat-screen-btn"
        ) as HTMLButtonElement | null;
        if (repeatBtn) {
          const event = new CustomEvent("click", {
            bubbles: true,
            cancelable: true,
            detail: { isProgrammatic: true },
          });
          repeatBtn.dispatchEvent(event);
        }
      }
    }
    autoFillForm(formData);
  }
}
