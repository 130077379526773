import "./CaseScreen.css";

import React, { useEffect, useRef } from "react";

import { FormInput } from "components/FormInput";
import { Button } from "components/Button";

import {
  autoFillForm,
  deleteScreen,
  implementInterviewLogic,
  implementScreensLogics,
  initializeRepeatScreens,
  navigateScreens,
  repeatScreen,
  updateInputElementsForRole,
} from "utils/caseScreen.utils";

import PrevArrow from "icons/prev-arrow.svg";
import NextArrow from "icons/next-arrow.svg";

import { ScreenWithQuestions } from "models/screen.models";
import { Question } from "models/question.models";
import { Case } from "models/case.models";

import useApi from "hooks/useApi";

interface Props {
  interviewData: {
    interview_logic: string;
    screens: ScreenWithQuestions[];
  };
  rolesData: any;
  contacts: any;
  caseData: Case;
}

export const CaseScreen = ({
  interviewData,
  rolesData,
  contacts,
  caseData,
}: Props): JSX.Element => {
  const roles = caseData.roles;
  const formRef = useRef<HTMLFormElement>(null);

  const initialConfig = {
    url: "https://skaffolding.com/cases/api/cases/",
    method: "GET",
  };

  const { fetchData, setConfig } = useApi<any>(initialConfig);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    action: string
  ) => {
    e.preventDefault();
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const data = Object.fromEntries(formData.entries()) as {
        [key: string]: FormDataEntryValue;
      };

      const payload = {
        responses: data,
        action: action,
      };

      const configOverride = {
        url: `https://skaffolding.com/cases/api/cases/${caseData.id}/`,
        method: "PATCH",
        data: payload,
      };

      try {
        const response = await fetchData(configOverride);
        if (response) {
          console.log("Data submitted successfully", response.data);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    }
  };

  useEffect(() => {
    if (
      typeof implementLogics === "function" &&
      interviewData.interview_logic
    ) {
      implementInterviewLogic();
      implementScreensLogics();

      document.addEventListener("change", implementInterviewLogic);
      document.addEventListener("change", implementScreensLogics);
      document.addEventListener("change", function (event: Event) {
        const target = event.target as HTMLSelectElement;

        // Check if the changed element is a dropdown
        if (target && target.tagName === "SELECT") {
          // Get the selected value from the dropdown
          const selectedValue = target.value;
          const newLabel = target.name;

          // Create properties fields with the selected value
          createFieldsWithLabel(selectedValue, newLabel);
          implementInterviewLogic();
          implementScreensLogics();
        }
      });

      setTimeout(() => {
        navigateScreens();
        autoFillForm(caseData?.responses || {});
        initializeRepeatScreens(
          caseData?.responses || {},
          caseData?.responses?.screens_data_dict
        );
      }, 0);
    }

    roles.forEach((role: any) => {
      const names = rolesData[role.role_name] || [];
      names.forEach((name: string, index: number) => {
        const selectedData = contacts.find((item: any) => item.name === name);
        updateInputElementsForRole(role.role_name, selectedData, index);
      });
    });
  }, [interviewData, roles, rolesData, contacts, caseData, implementLogics]);

  return (
    <>
      <CaseNavigation handleSubmit={handleSubmit} />
      <div className="case-screen">
        <div className="screens-sidebar-panel">
          <div id="screens-sidebar" className="screens-sidebar"></div>
        </div>
        <form ref={formRef} id="interview-form" className="interview-form-box">
          <div id="interview-screen-list" className="interview-screen-list">
            <p id="p_id_interview_logic">
              <input
                type="hidden"
                name="logic"
                disabled={true}
                id="id_interview_logic"
                value={interviewData?.interview_logic}
              />
            </p>
            <p id="p_id_make_tag">
              <input type="hidden" name="make_files" id="id_make_files" />
            </p>
            <div id="p_roles">
              {roles.map((role: any) => {
                const names = rolesData[role.role_name] || [];
                return (
                  <div key={role.id} className="role-group">
                    <input
                      type="number"
                      id={`id_${role.role_name}.counter`}
                      name={`${role.role_name}.counter`}
                      defaultValue={names.length}
                      hidden
                    />
                    {names.map((name: string, index: number) => (
                      <div key={`${role.role_name}-${index}`}>
                        <input
                          hidden
                          type="text"
                          id={`id_${role.role_name}%${index}`}
                          value={name}
                          name={role.role_name}
                          readOnly
                          data-total={index === 0 ? names.length : undefined}
                        />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <p id="p_repeatable_counters">
              {interviewData.screens.map(
                (screen) =>
                  screen.screen_type === "repeatable" && (
                    <input
                      key={screen.screen_name}
                      type="number"
                      id={`id_${screen.screen_name}`}
                      name={screen.screen_name}
                      style={{ display: "none" }}
                      defaultValue={"1"}
                    />
                  )
              )}
            </p>
            <p id="p_excess_variables"></p>
            {interviewData.screens.map((screen) => (
              <div
                key={screen.screen_name}
                id={`p_id_${screen.screen_name}`}
                // @ts-ignore
                name={screen.screen_name}
                className={`card card-info screen hide-screen ${
                  screen.screen_type === "repeatable"
                    ? "repeatable-screen"
                    : "single-screen"
                }`}
                data-screen-count="0"
                data-screen-max-count="999"
                style={{ display: "none" }}
              >
                <div className="card-header">
                  <h3 className="card-title">{screen.screen_name}</h3>
                  {screen.screen_type === "repeatable" && (
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn-xs btn-secondary repeat-screen-btn"
                      >
                        Repeat
                      </button>
                      <button
                        type="button"
                        className="btn-xs btn-secondary delete-screen-btn"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                <div className="card-contents">
                  {screen.containers.map((row: any, rowIndex: any) => (
                    <div
                      key={rowIndex}
                      className={`grid-row ${
                        row.length > 1 ? "two-column" : "one-column"
                      }`}
                    >
                      {row.map((item: any) => {
                        const question = screen.questions.find(
                          (q) => q.variable_name === item.item
                        );
                        if (!question) return null;

                        return (
                          <React.Fragment key={item.id}>
                            {question.variable_type !== "instruction" && (
                              <div
                                id={`p_id_${question.variable_name}`}
                                className="question-container"
                              >
                                {renderQuestionInput(question)}
                              </div>
                            )}
                            {question.variable_type === "instruction" &&
                              question.instructionLogic && (
                                <div
                                  id={`p_logic_of_${question.variable_name}`}
                                >
                                  {renderQuestionInput(question)}
                                </div>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ))}

                  <div
                    id={`p_id_logic_${screen.screen_name}`}
                    style={{ display: "none" }}
                  >
                    <input
                      type="hidden"
                      id={`id_logic_${screen.screen_name}`}
                      name={`logic_${screen.screen_name}`}
                      data-logic={screen.logic}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </form>
        <div style={{ display: "none" }} id="temp-interview-screen-list">
          {interviewData.screens.map((screen) => (
            <div
              key={screen.screen_name}
              id={`p_temp_id_${screen.screen_name}`}
              // @ts-ignore
              name={screen.screen_name}
              className={`card card-info screen hide-screen ${
                screen.screen_type === "repeatable"
                  ? "repeatable-screen"
                  : "single-screen"
              }`}
              data-screen-count="0"
              data-screen-max-count="999"
              style={{ display: "none" }}
            >
              <div className="card-header">
                <h3 className="card-title">{screen.screen_name}</h3>
                {screen.screen_type === "repeatable" && (
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn-xs btn-secondary repeat-screen-btn"
                    >
                      Repeat
                    </button>
                    <button
                      type="button"
                      className="btn-xs btn-secondary delete-screen-btn"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <div className="card-contents">
                {screen.containers.map((row: any, rowIndex: any) => (
                  <div
                    key={rowIndex}
                    className={`grid-row ${
                      row.length > 1 ? "two-column" : "one-column"
                    }`}
                  >
                    {row.map((item: any) => {
                      const question = screen.questions.find(
                        (q) => q.variable_name === item.item
                      );
                      if (!question) return null;

                      return (
                        <React.Fragment key={item.id}>
                          {question.variable_type !== "instruction" && (
                            <div
                              id={`p_id_${question.variable_name}`}
                              className="question-container"
                            >
                              {renderQuestionInput(question)}
                            </div>
                          )}
                          {question.variable_type === "instruction" &&
                            question.instructionLogic && (
                              <div id={`p_logic_of_${question.variable_name}`}>
                                {renderQuestionInput(question)}
                              </div>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                ))}

                <div id={`p_id_logic_${screen.screen_name}`}>
                  <input
                    type="hidden"
                    id={`id_logic_${screen.screen_name}`}
                    name={`logic_${screen.screen_name}`}
                    data-logic={screen.logic}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CaseNavigation handleSubmit={handleSubmit} />
    </>
  );
};

const renderQuestionInput = (question: Question) => {
  switch (question.variable_type) {
    case "text":
      return (
        <FormInput
          border="off"
          background="light"
          label={question.label}
          name={question.variable_name}
          id={`id_${question.variable_name}`}
          type="string"
        />
      );
    case "number":
      return (
        <FormInput
          border="off"
          background="light"
          label={question.label}
          name={question.variable_name}
          id={`id_${question.variable_name}`}
          type="number"
        />
      );
    case "date":
      return (
        <>
          <label
            htmlFor={`id_${question.variable_name}`}
            className="custom-label"
          >
            {question.label}
          </label>
          <input
            className="custom-date"
            type="date"
            id={`id_${question.variable_name}`}
            name={question.variable_name}
          />
        </>
      );
    case "yes/no":
      return (
        <>
          <label
            htmlFor={`id_${question.variable_name}`}
            className="custom-label"
          >
            {question.label}
          </label>
          <select
            name={question.variable_name}
            className="custom-select form-control"
            id={`id_${question.variable_name}`}
            defaultValue={"unknown"}
          >
            <option value="true">true</option>
            <option value="false">false</option>
            <option value="unknown">unknown</option>
          </select>
        </>
      );
    case "multiples_choices":
      return (
        <>
          <label
            htmlFor={`id_${question.variable_name}`}
            className="custom-label"
          >
            {question.label}
          </label>
          <select
            name={question.variable_name}
            className="custom-select form-control"
            id={`id_${question.variable_name}`}
          >
            {question.choices?.split(",").map((choice) => (
              <option key={choice.trim()} value={choice.trim()}>
                {choice.trim()}
              </option>
            ))}
          </select>
        </>
      );
    case "instruction":
      return (
        <input
          style={{ display: "none" }}
          id={`id_${question.variable_name}`}
          name={question.variable_name}
          data-logic={question.instructionLogic || ""}
        />
      );
    default:
      return null;
  }
};

document.addEventListener("click", function (event: Event) {
  // Type guard to ensure the target is an Element
  if (
    event.target instanceof Element &&
    event.target.classList.contains("repeat-screen-btn")
  ) {
    // Find the parent screen for this button
    const screen = event.target.closest(".repeatable-screen");

    // Call repeatScreen function if screen is found
    if (screen instanceof HTMLElement) {
      if (
        event instanceof CustomEvent &&
        event.detail &&
        event.detail.isProgrammatic
      ) {
        repeatScreen(screen, true); // programmatic click
      } else {
        repeatScreen(screen); // user click
        document.dispatchEvent(
          new Event("change", {
            bubbles: true,
            cancelable: true,
            composed: true,
          })
        );
      }
    }
  }

  if (
    event.target instanceof Element &&
    event.target.classList.contains("delete-screen-btn")
  ) {
    // Find the parent screen for this button
    const screen = event.target.closest(".repeatable-screen");

    // Call deleteScreen function if screen is found
    if (screen instanceof HTMLElement) {
      deleteScreen(screen);
      document.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
          composed: true,
        })
      );
    }
  }
});

interface CaseNavigationProps {
  handleSubmit: any;
}

export const CaseNavigation: React.FC<CaseNavigationProps> = ({
  handleSubmit,
}) => {
  return (
    <div className="case-navigation">
      <div className="left-buttons"></div>
      <div className="center-buttons">
        <Button
          buttonName="Previous"
          buttonImage={PrevArrow}
          extraClassName={"prevButton"}
        />
        <Button
          buttonName="Next"
          rightButtonImage={NextArrow}
          extraClassName={"nextButton"}
        />
      </div>
      <div className="right-buttons">
        <Button
          buttonName="Save"
          onClick={(e: any) => handleSubmit(e, "save")}
        />
        <Button
          buttonName="Submit"
          onClick={(e: any) => handleSubmit(e, "publish")}
        />
      </div>
    </div>
  );
};
